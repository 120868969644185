.root {
  .required {
    font-size: 14px;
    color: #d3180c;
  }

  .inputWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #e3e5e6;
    border-radius: 8px;

    padding: 16px;
    width: 100%;

    &.errorInput {
      border: 1px solid #d3180c;
    }

    .input {
      border: 0px solid #e3e5e6;
      font-weight: 400;
      font-size: 16px;
      line-height: 16px;

      outline: none;

      flex: 1;

      &::placeholder {
        color: #72777a;
      }
    }


  }

  .label {
    font-weight: 400;
    font-size: 16px;

    color: #72777a;

    margin-bottom: 8px;

    display: block;
  }

  .helper {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;

    color: #72777a;

    margin-top: 8px;
  }

  .error {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;

    color: #d3180c;

    margin-top: 8px;
  }
}
