@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+Hebrew:wght@200;300;400;500;600&display=swap");

html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
}

* {
  font-family: "Noto Sans Hebrew", sans-serif;
  box-sizing: border-box;
}

main.root {
  height: 100%;
  width: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

.padded {
  padding-right: 24px;
  padding-left: 24px;
}

.fullHeight {
  flex: 1;
}

.rtl {
  direction: rtl;
}
.ltr {
  direction: ltr;
}

@media (min-width: 700px) {
  body {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  main.root {
    max-width: 450px;
    max-height: 844px;

    border-radius: 16px;
    overflow: hidden;

    box-shadow: 9px 10px 25px 0px rgba(0, 0, 0, 0.1);
  }
}
